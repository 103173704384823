<template>
  <div>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
        <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
            <div>
            <strong>{{ snackbar.title }}</strong>
            </div>
            <div>{{ snackbar.text }}</div>
        </v-layout>
        </v-layout>
        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
        <v-icon>clear</v-icon>
        </v-btn>
    </v-snackbar>
    <v-container fluid class="mt-3">
      <v-row>
        <v-col cols="12" class="mb-0 mt-0">
          <div class="text-justified align-items-center">
            <h6 class="text-dark font-weight-semibold mb-3 mt-2">
              Hi, Welcome Back <span class="text-primary text-capitalize">{{$store.state.user.username}} !</span>
            </h6>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-card class="rounded-l purple lighten-4" outlined height="198px">
            <v-card-title class="">
              <div class="d-flex">
                <v-icon color="purple">
                  mdi-bucket
                </v-icon>
                <div class="ml-3">
                  Izin Done
                </div>
              </div>
            </v-card-title>
            <v-card-text  class="p-3">
              <v-container class="py-auto fill-height">
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="8">
                    <h2 style="font-weight:600" class="pa-0 ma-0 purple--text text-center">
                      {{total_done}}
                    </h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col> 
        <v-col cols="12" sm="12" md="3">
          <v-card class="rounded-l green lighten-4" outlined height="198px">
            <v-card-title class="">
              <div class="d-flex">
                <v-icon color="green" >
                  mdi-chart-box-plus-outline
                </v-icon>
                <div class="ml-3">
                  Izin Progress
                </div>
              </div>
            </v-card-title>
            <v-card-text class="p-3">
              <v-container class="py-auto fill-height">
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="8">
                    <h2 style="font-weight:600" class="pa-0 ma-0 green--text text-center">
                      {{total_progress}}
                    </h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-card class="rounded-l lighten-4 pink" outlined height="198px">
            <v-card-title class="">
              <div class="d-flex">
                <v-icon color="pink">
                  mdi-chart-bubble
                </v-icon>
                <div class="ml-3">
                  Izin Cancel
                </div>
              </div>
            </v-card-title>
            <v-card-text class="p-3">
              <v-container class="py-auto fill-height">
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="8">
                    <h2 style="font-weight:600" class="pa-0 ma-0 pink--text text-center">
                      0
                    </h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-card class="rounded-l" color="#e8ecef" outlined to="/admin/setting">
            <v-card-title class="mb-0 pb-0">
              <v-icon color="primary" class="mr-3">
                mdi-account-circle
              </v-icon>
              Atur Layanan
              <v-spacer></v-spacer>
              <v-icon large color="primary" @click="dialogInfo = true" to="/admin/setting">
                mdi-arrow-right
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-0 pb-0"></v-divider>
              <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#e8ecef">
                <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="font-size: 14px;">Pendirian PT</v-list-item-title>
                    <v-list-item-subtitle class="font-12">Pendirian Perusahaan</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="font-size: 14px;">Pendirian PT Perorangan</v-list-item-title>
                    <v-list-item-subtitle class="font-12">Pendirian Perusahaan</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="font-size: 14px;">Pendirian CV</v-list-item-title>
                    <v-list-item-subtitle class="font-12">Pendirian Perusahaan</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="mt-6 rounded-xxl pb-4 elevation-5" height="auto" color="#fff">
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="itemLists"
          class="elevation-1 mb-5"
          :items-per-page="30"
          :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50, -1]
          }"
          :loading="$store.state.overlay"
          height="440"
          fixed-header
          :divider="true"
          :light="true"
          :search="searchItem" 
          :item-class="tr_datatable"
      >    
          <template v-slot:top>
              <v-toolbar flat color="white" class="mt-2 mb-2">
                  <div class="d-flex w-100">
                      <v-text-field solo style="max-width: 300px;" class="border-12 mr-3 " v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                      <v-spacer></v-spacer>
                      <v-btn
                      small
                      color="#fff"
                      class="py-5 mr-3 hidden-sm-and-down"
                      @click="getPullData()"
                      >
                          <v-icon>mdi-cached</v-icon>
                      </v-btn>
                      <v-btn
                      small
                      color="#fff"
                      class="py-5 mr-3"
                      @click="dialogfilter = true"
                      >
                          <v-icon>mdi-filter-variant</v-icon>
                      </v-btn>
                      <v-btn
                      small
                      color="#005c37"
                      class="py-5 mr-3 text-white hidden-sm-and-down"
                      >
                          <v-icon>mdi-file-excel</v-icon>
                          <download-excel
                              class="text"
                              :fetch           = "exportExcel"
                              :fields="headersColumn"
                              :before-generate = "startDownload"
                              :before-finish   = "finishDownload">
                              Export Excel
                          </download-excel>
                      </v-btn>
                      <v-btn
                      small
                      color="success"
                      class="py-5 mr-0"
                      @click="[dialogCreateRequest = true, showDetail = false]"
                      >
                      <v-icon>mdi-plus</v-icon>
                      </v-btn>
                  </div>
              </v-toolbar>
          </template>
          <template v-slot:[`item.jenis_layanan`]="{ item }">
              {{ item.service.name }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-btn text color="green" v-if="item.status === 'onprogress'">On Progress</v-btn>
            <v-btn text color="purple" v-else-if="item.status === 'done'">DONE</v-btn>
            <v-btn text color="red" v-else>Cancel</v-btn>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ new Date(item.created_at).toISOString().substr(0, 10) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu
                bottom
                left
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="grey"
                        class="text-center"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list dense>
                    <v-list-item @click="detailItem(item)">
                        <v-list-item-icon>
                            <v-icon>mdi-magnify</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Detail</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="detailItemEdit(item)">
                        <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Update History</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item @click="deleteItem(item)">
                        <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->
                </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
        v-model="dialogCreateRequest"
        persistent
        max-width="1100px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card class="rounded-l">
            <v-card-title class="text-center mb-0 pb-0">
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex">
                            <div class="ml-5">
                              Perijinan Baru 
                            </div>
                            <v-spacer></v-spacer>
                            {{ showDetail == true ? detail.order_id : '' }}
                            <v-btn
                                class="mx-2"
                                fab
                                x-small
                                color="#e83e8c;"
                                @click="[dialogCreateRequest = false, showDetail = false, clear()]"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>
            </v-card-title>
            <v-card-text class="ma-0">
              <v-container fluid class="ma-0">
                <v-row>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Company Name <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="company_name"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Service <strong style="color:red;">*</strong><span></span></h6>
                    <v-autocomplete
                        dense
                        solo
                        clearable
                        v-model="service"
                        :items="services"
                        item-value="id"
                        item-text="name"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                    ></v-autocomplete> 
                  </v-col>
                  <v-col class="col-12" sm="6" md="4">
                    <h6 class="blue-lcd mb-1">Name <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="name"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="4">
                    <h6 class="blue-lcd mb-1">No Telp / HP <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="no_telp"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="4">
                    <h6 class="blue-lcd mb-1">Status <strong style="color:red;">*</strong><span></span></h6>
                    <v-autocomplete
                        dense
                        solo
                        clearable
                        v-model="status"
                        :items="statuses"
                        item-value="id"
                        item-text="name"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                    ></v-autocomplete> 
                  </v-col>
                  <v-col class="col-12" sm="12" md="12">
                      <v-textarea solo class="ma-0 pa-0 border-12" v-model="remark"
                        hide-details=true counter label="Remark" dense></v-textarea>
                  </v-col>
                  <!-- <v-col class="col-12" sm="12" md="12">
                      <v-file-input solo class="ma-0 pa-0 border-12" v-model="fileUpload" label="Picture file" small-chips counter multiple show-size append-icon="mdi-image" accept="image/*"></v-file-input>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col class="col-12" sm="12" md="12">
                    <h6 class="blue-lcd mb-1">Create History Izin <strong style="color:red;">*</strong><span></span></h6>
                  </v-col>
                  <v-col cols="12">
                    <v-simple-table
                      fixed-header   
                      height="280"                                                       
                    >
                        <thead>
                          <tr style="border-top:1px solid rgb(216 212 212);">
                              <th class="text-left" style="width:3%" >
                                  No
                              </th>
                              <th class="text-left"  style="width:50%">
                                  Description
                              </th>
                              <th class="text-left" style="width:20%">
                                  Status
                              </th>
                              <th class="text-left" style="width:25%">
                                  Date
                              </th>
                              <th class="text-center"  style="width:2%" v-if="showDetail == false"></th>                                                    
                          </tr>
                        </thead>  
                        <tbody>
                            <tr 
                                v-for="(detail, index) in details"
                                :key="detail.item_num"
                            >
                                <td class="text-left">{{ detail.item_num }}</td>
                                <td class="text-left">
                                  <v-text-field
                                    v-model="detail.description"
                                    solo
                                    dense
                                    clearable
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                  ></v-text-field>
                                </td>
                                <td class="text-left">
                                  <v-autocomplete
                                      dense
                                      solo
                                      clearable
                                      v-model="detail.status"
                                      :items="statuses"
                                      item-value="id"
                                      item-text="name"
                                      class="ma-0 pa-0 border-12"
                                      hide-details=true
                                  ></v-autocomplete> 
                                </td>
                                <td class="text-left">
                                  <v-menu
                                      ref="dateStartModal"
                                      v-model="dateStartModal"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="auto"
                                      >
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                          solo
                                          dense
                                          v-model="detail.date"
                                          label="Date"
                                          persistent-hint
                                          append-icon="mdi-calendar"
                                          v-bind="attrs"
                                          v-on="on"
                                          class="ma-0 pa-0 border-12"
                                          hide-details=true
                                          ></v-text-field>
                                      </template>
                                      <v-date-picker
                                          v-model="detail.date"
                                          no-title
                                          @input="[dateStartModal = false]"
                                      ></v-date-picker>
                                  </v-menu>
                                </td>
                                <td>
                                    <v-btn x-small class="text-white" color="red" @click="deleteDetail(detail, index)" v-if="showDetail == false">
                                        <v-icon small>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-if="showDetail == false">
                                <td colspan="4">
                                    <v-btn block class="p-3 py-4" color="#d8dee2" @click="submitDetail()" >
                                        <v-icon>
                                            mdi-plus
                                        </v-icon>
                                        Tambah baris satu
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="text-center" >
                <v-row justify="center" v-if="showDetail == false">
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l mr-2 p-3" color="#fff" @click="[dialogCreateRequest = false, showDetail = false, clear()]">Cancel</v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l text-white p-3" color="success" @click="submit()">Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogEditRequest"
        persistent
        max-width="1100px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card class="rounded-l">
            <v-card-title class="text-center mb-0 pb-0">
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex">
                            <div class="ml-5">
                              Update History Perijinan 
                            </div>
                            <v-spacer></v-spacer>
                            {{ detail ? detail.order_id : '' }}
                            <v-btn
                                class="mx-2"
                                fab
                                x-small
                                color="#e83e8c;"
                                @click="[dialogEditRequest = false, clear()]"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>
            </v-card-title>
            <v-card-text class="ma-0">
              <v-container fluid class="ma-0">
                <v-row>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Company Name <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="company_name"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Service <strong style="color:red;">*</strong><span></span></h6>
                    <v-autocomplete
                        dense
                        solo
                        clearable
                        v-model="service"
                        :items="services"
                        item-value="id"
                        item-text="name"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                    ></v-autocomplete> 
                  </v-col>
                  <v-col class="col-12" sm="6" md="4">
                    <h6 class="blue-lcd mb-1">Name <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="name"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="4">
                    <h6 class="blue-lcd mb-1">No Telp / HP <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="no_telp"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="4">
                    <h6 class="blue-lcd mb-1">Status <strong style="color:red;">*</strong><span></span></h6>
                    <v-autocomplete
                        dense
                        solo
                        clearable
                        v-model="status"
                        :items="statuses"
                        item-value="id"
                        item-text="name"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                    ></v-autocomplete> 
                  </v-col>
                  <v-col class="col-12" sm="12" md="12">
                      <v-textarea solo class="ma-0 pa-0 border-12" v-model="remark"
                        hide-details=true counter label="Remark *" dense></v-textarea>
                  </v-col>
                  <!-- <v-col class="col-12" sm="12" md="12">
                      <v-file-input solo class="ma-0 pa-0 border-12" v-model="fileUpload" label="Picture file" small-chips counter multiple show-size append-icon="mdi-image" accept="image/*"></v-file-input>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col class="col-12" sm="12" md="12">
                    <h6 class="blue-lcd mb-1">Create History Izin <strong style="color:red;">*</strong><span></span></h6>
                  </v-col>
                  <v-col cols="12">
                    <v-simple-table
                      fixed-header   
                      height="280"                                                       
                    >
                        <thead>
                          <tr style="border-top:1px solid rgb(216 212 212);">
                              <th class="text-left" style="width:3%" >
                                  No
                              </th>
                              <th class="text-left"  style="width:50%">
                                  Description
                              </th>
                              <th class="text-left" style="width:20%">
                                  Status
                              </th>
                              <th class="text-left" style="width:25%">
                                  Date
                              </th>
                              <th class="text-center"  style="width:2%"></th>                                                    
                          </tr>
                        </thead>  
                        <tbody>
                            <tr 
                                v-for="(detail, index) in details"
                                :key="detail.item_num"
                            >
                                <td class="text-left">{{ detail.item_num }}</td>
                                <td class="text-left">
                                  <v-text-field
                                    v-model="detail.description"
                                    solo
                                    dense
                                    clearable
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                  ></v-text-field>
                                </td>
                                <td class="text-left">
                                  <v-autocomplete
                                      dense
                                      solo
                                      clearable
                                      v-model="detail.status"
                                      :items="statuses"
                                      item-value="id"
                                      item-text="name"
                                      class="ma-0 pa-0 border-12"
                                      hide-details=true
                                  ></v-autocomplete> 
                                </td>
                                <td class="text-left">
                                    <v-menu
                                      ref="dateStartModal"
                                      v-model="dateStartModal"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="auto"
                                      >
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                          solo
                                          dense
                                          v-model="detail.date"
                                          label="Date"
                                          persistent-hint
                                          append-icon="mdi-calendar"
                                          v-bind="attrs"
                                          v-on="on"
                                          class="ma-0 pa-0 border-12"
                                          hide-details=true
                                          ></v-text-field>
                                      </template>
                                      <v-date-picker
                                          v-model="detail.date"
                                          no-title
                                          @input="[dateStartModal = false]"
                                      ></v-date-picker>
                                  </v-menu>
                                </td>
                                <td>
                                    <v-btn x-small class="text-white" color="red" @click="deleteDetail(detail, index)" >
                                        <v-icon small>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr >
                                <td colspan="4">
                                    <v-btn block class="p-3 py-4" color="#d8dee2" @click="submitDetail()" >
                                        <v-icon>
                                            mdi-plus
                                        </v-icon>
                                        Tambah baris satu
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="text-center">
                <v-row justify="center">
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l mr-2 p-3" color="#fff" @click="[dialogEditRequest = false, clear()]">Cancel</v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l text-white p-3" color="success" @click="submitEdit()">Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogfilter"
        persistent
        max-width="600px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card class="rounded-l">
        <v-card-title class="text-center mb-0 pb-0">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex">
                        <div class="ml-5">
                          Filter
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialogfilter = false]"
                        >
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
        <v-card-text class="ma-0">
          <v-container fluid class="ma-0">
            <v-row>
              <v-col class="col-12" sm="6" md="6">
                <h6 class="blue-lcd mb-1">Service</h6>
                <v-autocomplete
                    dense
                    solo
                    clearable
                    v-model="service"
                    :items="services"
                    item-value="id"
                    item-text="name"
                    class="ma-0 pa-0 border-12"
                    hide-details=true
                ></v-autocomplete> 
              </v-col>
              <v-col class="col-12" sm="6" md="6">
                <h6 class="blue-lcd mb-1">Status </h6>
                <v-autocomplete
                    dense
                    solo
                    clearable
                    v-model="status"
                    :items="statuses"
                    item-value="id"
                    item-text="name"
                    class="ma-0 pa-0 border-12"
                    hide-details=true
                ></v-autocomplete> 
              </v-col>
              <v-col class="col-12" sm="6" md="6">
                <h6 class="blue-lcd mb-1">Start Date </h6>
                <v-menu
                    ref="dateStartModal"
                    v-model="dateStartModal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        solo
                        dense
                        v-model="dateStartModel"
                        label="Start Date"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                        clearable
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateStartModel"
                        no-title
                        @input="[dateStartModal = false]"
                    ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="col-12" sm="6" md="6">
                <h6 class="blue-lcd mb-1">End Date </h6>
                <v-menu
                    ref="dateEndModal"
                    v-model="dateEndModal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        solo
                        dense
                        v-model="dateEndModel"
                        label="Start Date"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                        clearable
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateEndModel"
                        no-title
                        @input="[dateEndModal = false]"
                    ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-row justify="center">
              <v-col cols="12" xs="12" sm="3" md="3">
                  <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogfilter = false, clear()]">Cancel</v-btn>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3">
                  <v-btn block class="rounded-l text-white" color="#20bf6b" @click="[getPullData(), dialogfilter = false]">Filter</v-btn>
              </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
        v-model="dialogInfo"
        persistent
        max-width="900px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card class="rounded-l" color="#e8ecef">
        <v-card-title class="text-center mb-0 pb-0">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex">
                        <div class="ml-5">
                          <v-icon color="primary" class="mr-3">
                            mdi-account-circle
                          </v-icon> Info Employee
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialogInfo = false]"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
        <v-card-text>
          <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
            <v-row>
              <v-col class="col-12" sm="6" md="4">
                <v-card class="rounded-l" outlined min-height="485px" color="#e8ecef">
                  <v-card-text>
                    <v-img :src="avatar" v-if="$store.state.user.avatar" min-height="220px"></v-img>
                    <v-img src="@/assets/default-user.png" v-else></v-img>
                    <v-btn
                        block
                        color="accent"
                        elevation="2"
                        outlined
                        class="mt-5"
                    >
                        Pilih Foto
                    </v-btn>
                    <p class="css-bqy72x mt-5 font-12" style="font-size: 12px;">Besar file: maksimum (2 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="col-12" sm="6" md="8">
                <v-card class="elevation-1 rounded-l">
                  <v-card-text>
                    <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#e8ecef">
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.company_name }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Company</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.office_name }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Office</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.dept_name ?  $store.state.user.employee.dept_name : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Department</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.division_name ? $store.state.user.employee.division_name : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Divisi</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.sec_name ? $store.state.user.employee.sec_name : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Section</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.group_name ? $store.state.user.employee.group_name : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Group Section</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.jabatan ? $store.state.user.employee.jabatan : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Position</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.emp_stat ? $store.state.user.employee.emp_stat : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Employee Status</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.emp_type ? $store.state.user.employee.emp_type : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Employee Type</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.dt_join == '1900-01-01 00:00:00.000' ? '-' : new Date($store.state.user.employee.dt_join).toISOString().substr(0, 10)  }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Date Join</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.dt_resign == '1900-01-01 00:00:00.000' ? '-' : new Date($store.state.user.employee.dt_resign).toISOString().substr(0, 10) }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Date Resign</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>                
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>  -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {order} from "@/backend-api/order"
import {service_api} from "@/backend-api/service_api"

export default {
  data: () => ({
    snackbar: {
      color: null,
      icon: null,
      mode: null,
      position: "top",
      text: null,
      timeout: 7500,
      title: null,
      visible: false
    },
    headers: [
        { text: 'ID ORDER', value: 'order_id', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'NAMA PT', value: 'company_name', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'NAMA', value: 'name', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'NO TELP / HP', value: 'no_telp', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'JENIS LAYANAN', value: 'jenis_layanan', align: 'center', class: 'primary--text blue lighten-5' },
        { text: 'STATUS', value: 'status', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'DESCRIPTION', value: 'description', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'CREATED DATE', value: 'created_at', align: 'left', class: 'primary--text blue lighten-5' },      
        { text: '', value: 'actions', align: 'center', class: 'primary--text blue lighten-5' },
    ],
    dialogInfo: false,
    headersColumn:{
      'ID ORDER': 'order_id',
      'NAMA PT': 'company_name',
      'NAMA': 'name',
      'NO TELP / HP': 'no_telp',
      'JENIS LAYANAN': 'service.name',
      'STATUS': 'status',
      'DESCRIPTION': 'description',
      'CREATED DATE': 'created_at',
    },
    itemLists: [],
    searchItem: '',
    services:[],
    service: '',
    dialogCreateRequest: false,
    company_name: '',
    remark: '',
    name: '',
    no_telp: '',
    status: '',
    statuses: [
      {
        id:'onprogress',
        name: 'ON PROGRESS'
      },
      {
        id:'done',
        name: 'DONE'
      },
      {
        id:'cancel',
        name: 'CANCEL'
      }
    ],
    details: [],
    dateStartModal: false,
    showDetail: false,
    detail: null,
    dialogEditRequest: false,
    dialogfilter: false,
    dateStartModal: false,
    dateStartModel: '',
    dateEndModal: false,
    dateEndModel: ''
  }),
  methods:{
    tr_datatable(item) {
        var rowClass = 'tr_datatable'
        return rowClass;
    },
    clear(){
      this.company_name = ''
      this.service = ''
      this.name = ''
      this.no_telp = ''
      this.status = ''
      this.remark = ''
      this.details = []
      this.showDetail = false
    },
    async getDataService(){
      var respData = await service_api.index(``, null, false, false, false)
      if (respData.status === 200) {
        this.services = respData.data.data
      }
    },
    async getPullData(){
      this.$store.dispatch('setOverlay', true)
      var trans_type = false
      var respData = await order.index(`?service_id=${this.service ? this.service : ''}&status=${this.status ? this.status : ''}&start_date=${this.dateStartModel ? this.dateStartModel : ''}&end_date=${this.dateEndModel ? this.dateEndModel : ''}`, null, false, false, false)
      if (respData.status === 200) {
        this.itemLists = respData.data.data
        this.$store.dispatch('setOverlay', false)
      } else{
        this.$store.dispatch('setOverlay', false)
      }
    },
    async submit(){
      if (this.company_name == '' || this.service == '' || this.name == '' || this.no_telp == '' || this.status == '' || this.details.length == 0 || this.remark == '') {
        this.snackbar = {
          color: "info",
          icon: "mdi-alert-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Info",
          text: 'Please fill your fileds and submit for save',
          visible: true
        };

        return false
      }

      this.$store.dispatch('setOverlay', true)
      var reqBody = {
        'service_id': this.service,
        'company_name': this.company_name,
        'name': this.name,
        'no_telp': this.no_telp,
        'status': this.status,
        'description': this.remark,
        'histories': this.details
      }
      const respData = await order.store('', reqBody, false, false, false)
      if (respData.status === 200) {
        this.clear()
        this.getPullData()
        this.dialogCreateRequest = false
        this.snackbar = {
          color: "success",
          icon: "mdi-alert-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Success",
          text: 'Success tambah perizinan',
          visible: true
        };
      }

    },
    async submitEdit(){
      if (this.company_name == '' || this.service == '' || this.name == '' || this.no_telp == '' || this.status == '' || this.details.length == 0) {
        this.snackbar = {
          color: "info",
          icon: "mdi-alert-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Info",
          text: 'Please fill your fileds and submit for save',
          visible: true
        };

        return false
      }

      this.$store.dispatch('setOverlay', true)
      var reqBody = {
        'order_id' : this.detail.id,
        'service_id': this.service,
        'company_name': this.company_name,
        'name': this.name,
        'no_telp': this.no_telp,
        'status': this.status,
        'description': this.remark,
        'histories': this.details
      }
      const respData = await order.update('', reqBody, false, false, false)
      if (respData.status === 200) {
        this.clear()
        this.getPullData()
        this.dialogEditRequest = false
        this.snackbar = {
          color: "success",
          icon: "mdi-alert-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Success",
          text: 'Success memperbarui perizinan',
          visible: true
        };
      }
    },
    async exportExcel(){
      var respData = await order.index(`?service_id=${this.service ? this.service : ''}&status=${this.status ? this.status : ''}&start_date=${this.dateStartModel ? this.dateStartModel : ''}&end_date=${this.dateEndModel ? this.dateEndModel : ''}`, null, false, false, false)
      if (respData.status === 200) {
        return respData.data.data
      }
    },
    startDownload(){
      this.$store.dispatch('setOverlay', true)
    },
    finishDownload(){
      this.$store.dispatch('setOverlay', false)
    },
    submitDetail(){
      this.details.push(
        {
          item_num : this.details.length + 1,
          description: '',
          date: '',
          status: ''
        }
      )
    },
    deleteDetail(detail, index){
      this.details.splice(index, 1);
    },
    detailItem(item){
      this.details = []
      this.detail = item
      this.dialogCreateRequest = true
      this.showDetail = true

      this.company_name = item.company_name
      this.service = item.service_id
      this.name = item.name
      this.no_telp = item.no_telp
      this.status = item.status
      this.remark = item.description

      for (let index = 0; index < item.order_histories.length; index++) {
        const element = item.order_histories[index];
        this.details.push(
          {
            item_num : this.details.length + 1,
            description:element.description,
            date: element.trans_date,
            status: element.status
          }
        )
        
      }
    },
    detailItemEdit(item){
      this.details = []
      this.detail = item
      this.dialogEditRequest = true
      this.company_name = item.company_name
      this.service = item.service_id
      this.name = item.name
      this.no_telp = item.no_telp
      this.status = item.status
      this.remark = item.description

      for (let index = 0; index < item.order_histories.length; index++) {
        const element = item.order_histories[index];
        this.details.push(
          {
            item_num : this.details.length + 1,
            description:element.description,
            date: element.trans_date,
            status: element.status
          }
        )
        
      }
    }
  },
  async mounted() { 
    this.$store.dispatch('setOverlay', true)
    await this.getDataService()
    await this.getPullData()
    this.$store.dispatch('setOverlay', false)
  },
  computed:{
    total_done(){
        var total_done = 0
        for (let index = 0; index < this.itemLists.length; index++) {
            if (this.itemLists[index].status == 'done') {
                total_done = total_done + 1;
            }
        }
        
        return (total_done | 0)
    },
    total_progress(){
        var total_progress = 0
        for (let index = 0; index < this.itemLists.length; index++) {
            if (this.itemLists[index].status == 'onprogress') {
                total_progress = total_progress + 1;
            }
        }
        
        return (total_progress | 0)
    },
    total_cancel(){
        var total_cancel = 0
        for (let index = 0; index < this.itemLists.length; index++) {
            if (this.itemLists[index].status == 'cancel') {
                total_cancel = total_cancel + 1;
            }
        }
        
        return (total_cancel | 0)
    },
  },
  watch: {
  }
}
</script>
  
<style scoped>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
  
</style>